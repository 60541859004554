@mixin font-face($font-family, $url, $weight: 400, $style: normal) {
	@font-face {
		font-family: "#{$font-family}";
		src: url('../fonts/#{$url}.ttf') format("ttf");
		font-weight: #{$weight};
		font-display: swap;
		font-style: $style;
	}
}

@include font-face('Montserrat', '../fonts/Montserrat-Regular');
@include font-face('21Cent', '../fonts/21Cent');