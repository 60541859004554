
@mixin for-small-desktop {
	@media (max-width: 1300px) {
		@content;
	}
}

@mixin for-tablet {
	@media (max-width: 1024px) {
		@content;
	}
}

@mixin for-tablet-small {
	@media (max-width: 770px) {
		@content;
	}
}

@mixin for-phone {
	@media (max-width: 600px) {
		@content;
	}
}