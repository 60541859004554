.wrapper {
	width: 100%;
	min-height: 100%;
	overflow: hidden;
}

.preload {
	@include fill();
	position: fixed;
	display: flex;
	align-items: center;
	justify-content: center;
	z-index: 100;

	&.finish{
		pointer-events: none;
		position: absolute;
	}

	&-fade{
		@include fill();
		background: #000;
	}

	&-img {
		position: relative;
		width: 130px;
		height: 130px;

		&__triangle {
			@include pos-center();
			transform: translate(-50%, -50%) scale(0);
			transform-origin: 50% 65%;
		}

		&__logo {
			@include pos-center();
			margin-top: 19px;

			img {
				width: 30px;
				opacity: 0;
				transform: scale(0.3) translateY(5px);
			}
		}
	}
}