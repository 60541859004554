html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, font, img, ins, kbd, q, s, samp,
small, strike, strong, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td, input, textarea {
	margin: 0;
	padding: 0;
	border: 0;
	outline: 0;
	background: transparent;
	font-size: 100%;
	vertical-align: baseline;
}

html, body {
	height: 100%;
	-webkit-font-smoothing: antialiased;
}

*{
	box-sizing: border-box;
}

body, input, textarea {
	font-family: $F_main;
}

a {
	&:hover {
		text-decoration: none;
	}
}

img {
	border: 0;
	max-width: 100%;
}