.content {
	max-width: 1440px;
	width: 100%;
	margin: 0 auto;
	position: relative;
	overflow: hidden;
	box-sizing: border-box;
	padding: 0 85px;

	&.visible {
		overflow: visible;
	}
}

h1, .h1 {
	font-size: $fs_h1;
	font-family: $F_title;
	font-weight: 400;
}

h2, .h2 {
	font-size: $fs_h2;
	font-family: $F_title;
}

h3, .h3 {
	font-size: $fs_h3;
}

h4, .h4 {
	font-size: $fs_h4;
}

.fs-small {
	font-size: $fs_small;
}


.flex {
	display: flex;
}

.jc-sb {
	justify-content: space-between;
}

.va-m {
	vertical-align: middle;
	align-items: center;
}

.va-b {
	vertical-align: bottom;
	align-items: flex-end;
}

.ta-r {
	text-align: right;
}

.ta-c {
	text-align: center;
}

.w100 {
	width: 100%;
}

.mt-20 {
	margin-top: 20px;
}

.mb-20 {
	margin-bottom: 20px;
}

.mb-30 {
	margin-bottom: 30px;
}

.mb-40 {
	margin-bottom: 40px;
}

.mb-80 {
	margin-bottom: 80px;
}

.btn {
	padding: 5px 8px;
	display: inline-block;
	cursor: pointer;
	text-decoration: none;
	font-size: $fs_small;

	color: white;
	border: 2px solid white;

	&:hover {
		background: white;
		color: black;
	}
}

.cross {
	position: relative;
	width: 15px;
	height: 15px;

	&:before, &:after {
		content: '';
		display: block;
		position: absolute;
		top: 50%;
		width: 100%;
		height: 2px;
		background: white;
		transform: rotate(45 eg)
	}

	&:after {
		transform: rotate(-45eg)

	}
}

.gamburger {
	display: inline-block;
	position: relative;
	height: 24px;
	width: 30px;
	border-bottom: 1px solid #fff;
	transition: all 0.4s ease-in-out;

	&:after, &:before {
		content: '';
		left: 0;
		width: 100%;
		height: 1px;
		position: absolute;
		top: 66%;
		background-color: #fff;
		transition: all 0.5s;
	}

	&:before {
		top: 30%;
	}

	&.active {
		border-bottom: 3px solid transparent;

		&:after {
			transform: rotate(45 eg);
			top: 67%;
		}

		&:before {
			transform: rotate(-45eg);
			top: 63%;
		}
	}
}


.input-cols {
	.col {
		padding-top: 0 !important;
		padding-bottom: 0 !important;
	}
}

.cols-2 {
	display: flex;
	flex-wrap: wrap;

	.col {
		width: 50%;
		box-sizing: border-box;
	}

	&.p5 {
		@include col-padding(5px);
	}

	&.p15 {
		@include col-padding(15px);
	}

	&.p40 {
		@include col-padding(40px);
	}
}

.cols-3 {
	display: flex;
	flex-wrap: wrap;

	.col {
		width: 33.333%;
		box-sizing: border-box;
	}

	&.p15 {
		@include col-padding(15px);
	}

	&.p10 {
		@include col-padding(10px);
	}
}

.cols-4 {
	display: flex;
	flex-wrap: wrap;

	.col {
		width: 25%;
		box-sizing: border-box;
	}

	&.p7 {
		@include col-padding(7px);
	}

	&.p20 {
		@include col-padding(20px);
	}
}

.cols-5 {
	display: flex;
	flex-wrap: wrap;

	.col {
		width: 20%;
		box-sizing: border-box;
	}

	&.p22 {
		@include col-padding(22px);
	}
}

@include for-small-desktop {
	.content {
		padding-left: 40px;
		padding-right: 40px;
	}
}

@include for-phone {
	.h1 {
		font-size: $fs_h2;
	}
}