/* header begin */
.header {
	width: 100%;
	position: fixed;
	top: 0;
	left: 0;
	padding-top: 45px;
	z-index: 9;
	color: #fff;

	> .content {
		display: flex;
		align-items: center;
		justify-content: center;
	}

	&__logo {
		margin-right: 26px;
	}

	&-menu {
		display: flex;
		align-items: center;
		justify-content: center;
		list-style: none;
		flex: 1;
		gap: 60px;

		li {
			margin-right: 25px;

			a {
				color: inherit;
				text-decoration: none;
			}
		}
	}

	&-right {
		display: flex;
		align-items: center;
	}

	&-btn {
		display: none;
	}
}

@include for-small-desktop {
	.header-menu {
		gap: 20px;
	}
}

@include for-tablet-small {
	.header-btn {
		display: block;
		position: absolute;
		right: 40px;
		top: 50%;
		margin-top: -15px;
	}
	.header-menu {
		a:not(.header__logo) {
			display: none;
		}
	}
}

@include for-phone{
	.header-btn {
		margin-top: -20px;
	}
	.header__logo{
		width: 210px;
		display: block;
		margin: 0
	}
}