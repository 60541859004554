.hero {
	position: relative;
	min-height: 100vh;
	display: flex;
	flex-direction: column;
	justify-content: flex-end;
	padding-bottom: 95px;
	padding-top: 100px;
	z-index: 1;
	background: #000;

	.content {
		position: relative;
		z-index: 2;
	}


	&-bg {
		@include fill();
		right: -5%;
		background-size: cover;
		background-position: center;
		background-attachment: fixed;
		animation: hero-bg 20s ease-in-out infinite;
		z-index: 0;
		opacity: 0.7;

		&:before, &:after {
			content: "";
			position: absolute;
			left: 0;
			top: 0;
			width: 100%;
			height: 20%;
			background: linear-gradient(180deg, #000000 -55.86%, rgba(0, 0, 0, 0) 100%);
			z-index: 1;
		}

		&:after {
			top: auto;
			bottom: 0;
			background: linear-gradient(0deg, #000000 -55.86%, rgba(0, 0, 0, 0) 100%);
		}
	}

	&-bg-mask {
		@include fill();
		right: -5%;
		left: -5%;
		mask: url('../img/start-mask.svg') no-repeat center;
		z-index: 1;

		&__img {
			@include fill();
			background-size: cover;
			background-position: center;
			transform: scale(1.2);
			background-attachment: fixed;
			animation: hero-bg-star 20s ease-in-out infinite;
			animation-delay: 2s;
		}


		&:after {
			content: "";
			@include fill();
			background: #000;
		}
	}


	&-content {
		position: relative;
		color: #fff;
		display: flex;
		align-items: flex-end;
		justify-content: space-between;
		width: 100%;
		flex: 1;

		&__address{
			font-size: 0.85rem;
			margin-top: 1rem;
		}

		.h1 {
			max-width: 100%;
			width: 400px;
			line-height: 1;
		}
	}

	&-news {
		display: flex;
		gap: 37px;
		width: 490px;
		max-width: 100%;

		&-itm {
			color: inherit;
			text-decoration: none;
			display: block;

			&__date {
				font-size: $fs_smallest;
				opacity: 0.7;
				margin-bottom: 9px;
			}

			&__title {
				line-height: 1.4;
			}
		}
	}
}

.soc-block {
	text-align: right;
}

.soc-list {
	display: flex;
	justify-content: flex-end;
	gap: 1.37rem;

	&-itm {
		path {
			transition: all .3s ease-in-out;
		}

		&:hover {
			path {
				fill: $c_main;
			}
		}
	}
}

.main-tel {
	display: inline-block;
	font-size: 3.125rem;
	color: #fff;
	text-decoration: none;
	font-family: $F_title;
	font-weight: 500;
	margin-top: 1.5rem;

	&:hover {
		color: $c_main;
	}
}

@keyframes hero-bg {
	0% {
		transform: translateX(0);
	}
	50% {
		transform: translateX(-5%);
	}
	100% {
		transform: translateX(0);
	}
}

@keyframes hero-bg-star {
	0% {
		transform: translateX(0) scale(1.2);
	}
	50% {
		transform: translateX(-8%) scale(1.3);
	}
	100% {
		transform: translateX(0) scale(1.2);
	}
}

@keyframes hero-bg-mask {
	0% {
		transform: translateX(0) scale(1);
	}
	50% {
		transform: translateX(8%) scale(1.1);
	}
	100% {
		transform: translateX(0) scale(1);
	}
}

@include for-tablet-small {
	.hero {
		padding-bottom: 45px;

		&-news {
			display: none;
		}

		&-content {
			justify-content: center;
		}

		&-bg {
			&:after {
				height: 40%;
			}
		}
	}

	.main-tel {
		font-size: 2.75rem;
	}

	.hero-bg-mask {
		mask-size: 75%;
	}
}

@include for-phone {
	.hero {
		padding-bottom: 25px;

		&-content {
			display: block;
		}
	}

	.main-tel {
		font-size: 2rem;
		margin-top: 0.5rem;
	}

	.soc-list-itm{
		svg{
			width: 35px;
			height: 35px;
		}
	}

	.soc-block{
		margin-top: 2rem;
	}
}