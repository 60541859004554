$F_main: 'Montserrat', sans-serif;
$F_title: '21 Cent';

$width_main: 1280;

$fs_h1: toRem(64);
$fs_h2: toRem(40);
$fs_h3: toRem(24);
$fs_h4: toRem(20);
$fs_small: toRem(14);
$fs_btn: toRem(15);
$fs_btn_small: toRem(13);
$fs_smallest: toRem(12);

$color_black: #000;

$c_d_gray: #333333;
$c_gray: #867f7b;
$c_light_gray: #e6e6e6;

$c_main: #BED4CD;
$c_main_hover: #6BA596;
$c_main_middle: #D8E5E1;
$c_main_light: #F2F6F5;

$c_second: #E3E8F0;
$c_second_hover: #BBCADF;
$c_second_middle: #E8ECF3;
$c_second_light: #F3F5F9;

$c_notification: #477FE8;
$c_notification_hover: #2A67D6;
$c_notification_middle: #689DFF;
$c_notification_light: rgba(212, 227, 255, 0.5);

$c_success: #16C79A;
$c_success_hover: #139D7B;
$c_success_middle: #5CD8B8;
$c_success_light: #E8F9F5;

$c_text: #102136;
$c_text_hover: #728095;
$c_text_middle: #ABB8CA;
$c_text_light: #FFFFFF;

$c_alarm: #F05454;
$c_alarm_hover: #CF2A2A;
$c_alarm_middle: #F58787;
$c_alarm_light: #FEEEEE;

$c_warning: #FFC453;
$c_warning_hover: #D9A237;
$c_warning_middle: #FFD687;
$c_warning_light: #FFF9EE;