/* CSS Document */
@font-face {
  font-family: "Montserrat";
  src: url("../fonts/../fonts/Montserrat-Regular.ttf") format("ttf");
  font-weight: 400;
  font-display: swap;
  font-style: normal;
}
@font-face {
  font-family: "21Cent";
  src: url("../fonts/../fonts/21Cent.ttf") format("ttf");
  font-weight: 400;
  font-display: swap;
  font-style: normal;
}
html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, font, img, ins, kbd, q, s, samp,
small, strike, strong, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td, input, textarea {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  background: transparent;
  font-size: 100%;
  vertical-align: baseline;
}

html, body {
  height: 100%;
  -webkit-font-smoothing: antialiased;
}

* {
  box-sizing: border-box;
}

body, input, textarea {
  font-family: "Montserrat", sans-serif;
}

a:hover {
  text-decoration: none;
}

img {
  border: 0;
  max-width: 100%;
}

.content {
  max-width: 1440px;
  width: 100%;
  margin: 0 auto;
  position: relative;
  overflow: hidden;
  box-sizing: border-box;
  padding: 0 85px;
}
.content.visible {
  overflow: visible;
}

h1, .h1 {
  font-size: 4rem;
  font-family: "21 Cent";
  font-weight: 400;
}

h2, .h2 {
  font-size: 2.5rem;
  font-family: "21 Cent";
}

h3, .h3 {
  font-size: 1.5rem;
}

h4, .h4 {
  font-size: 1.25rem;
}

.fs-small {
  font-size: 0.875rem;
}

.flex {
  display: flex;
}

.jc-sb {
  justify-content: space-between;
}

.va-m {
  vertical-align: middle;
  align-items: center;
}

.va-b {
  vertical-align: bottom;
  align-items: flex-end;
}

.ta-r {
  text-align: right;
}

.ta-c {
  text-align: center;
}

.w100 {
  width: 100%;
}

.mt-20 {
  margin-top: 20px;
}

.mb-20 {
  margin-bottom: 20px;
}

.mb-30 {
  margin-bottom: 30px;
}

.mb-40 {
  margin-bottom: 40px;
}

.mb-80 {
  margin-bottom: 80px;
}

.btn {
  padding: 5px 8px;
  display: inline-block;
  cursor: pointer;
  text-decoration: none;
  font-size: 0.875rem;
  color: white;
  border: 2px solid white;
}
.btn:hover {
  background: white;
  color: black;
}

.cross {
  position: relative;
  width: 15px;
  height: 15px;
}
.cross:before, .cross:after {
  content: "";
  display: block;
  position: absolute;
  top: 50%;
  width: 100%;
  height: 2px;
  background: white;
  transform: rotate(45 eg);
}
.cross:after {
  transform: rotate(-45eg);
}

.gamburger {
  display: inline-block;
  position: relative;
  height: 24px;
  width: 30px;
  border-bottom: 1px solid #fff;
  transition: all 0.4s ease-in-out;
}
.gamburger:after, .gamburger:before {
  content: "";
  left: 0;
  width: 100%;
  height: 1px;
  position: absolute;
  top: 66%;
  background-color: #fff;
  transition: all 0.5s;
}
.gamburger:before {
  top: 30%;
}
.gamburger.active {
  border-bottom: 3px solid transparent;
}
.gamburger.active:after {
  transform: rotate(45 eg);
  top: 67%;
}
.gamburger.active:before {
  transform: rotate(-45eg);
  top: 63%;
}

.input-cols .col {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

.cols-2 {
  display: flex;
  flex-wrap: wrap;
}
.cols-2 .col {
  width: 50%;
  box-sizing: border-box;
}
.cols-2.p5 {
  margin-left: -5px;
  width: -moz-calc(100% + 10px);
  width: -webkit-calc(100% + 10px);
  width: calc(100% + 10px);
}
.cols-2.p5 .col {
  padding: 5px;
}
.cols-2.p15 {
  margin-left: -15px;
  width: -moz-calc(100% + 30px);
  width: -webkit-calc(100% + 30px);
  width: calc(100% + 30px);
}
.cols-2.p15 .col {
  padding: 15px;
}
.cols-2.p40 {
  margin-left: -40px;
  width: -moz-calc(100% + 80px);
  width: -webkit-calc(100% + 80px);
  width: calc(100% + 80px);
}
.cols-2.p40 .col {
  padding: 40px;
}

.cols-3 {
  display: flex;
  flex-wrap: wrap;
}
.cols-3 .col {
  width: 33.333%;
  box-sizing: border-box;
}
.cols-3.p15 {
  margin-left: -15px;
  width: -moz-calc(100% + 30px);
  width: -webkit-calc(100% + 30px);
  width: calc(100% + 30px);
}
.cols-3.p15 .col {
  padding: 15px;
}
.cols-3.p10 {
  margin-left: -10px;
  width: -moz-calc(100% + 20px);
  width: -webkit-calc(100% + 20px);
  width: calc(100% + 20px);
}
.cols-3.p10 .col {
  padding: 10px;
}

.cols-4 {
  display: flex;
  flex-wrap: wrap;
}
.cols-4 .col {
  width: 25%;
  box-sizing: border-box;
}
.cols-4.p7 {
  margin-left: -7px;
  width: -moz-calc(100% + 14px);
  width: -webkit-calc(100% + 14px);
  width: calc(100% + 14px);
}
.cols-4.p7 .col {
  padding: 7px;
}
.cols-4.p20 {
  margin-left: -20px;
  width: -moz-calc(100% + 40px);
  width: -webkit-calc(100% + 40px);
  width: calc(100% + 40px);
}
.cols-4.p20 .col {
  padding: 20px;
}

.cols-5 {
  display: flex;
  flex-wrap: wrap;
}
.cols-5 .col {
  width: 20%;
  box-sizing: border-box;
}
.cols-5.p22 {
  margin-left: -22px;
  width: -moz-calc(100% + 44px);
  width: -webkit-calc(100% + 44px);
  width: calc(100% + 44px);
}
.cols-5.p22 .col {
  padding: 22px;
}

@media (max-width: 1300px) {
  .content {
    padding-left: 40px;
    padding-right: 40px;
  }
}
@media (max-width: 600px) {
  .h1 {
    font-size: 2.5rem;
  }
}
.cols {
  display: flex;
  flex-wrap: wrap;
}
.cols.p15 {
  margin-left: -15px;
  width: -moz-calc(100% + 30px);
  width: -webkit-calc(100% + 30px);
  width: calc(100% + 30px);
}
.cols.p15 .col {
  padding: 15px;
}
.cols.p30 {
  margin-left: -30px;
  width: -moz-calc(100% + 60px);
  width: -webkit-calc(100% + 60px);
  width: calc(100% + 60px);
}
.cols.p30 .col {
  padding: 30px;
}

.cols-2 .col {
  width: 50%;
}

.cols-3 .col {
  width: 33.333%;
}

.col-1 {
  width: 8.3333333333%;
}

.col-2 {
  width: 16.6666666667%;
}

.col-3 {
  width: 25%;
}

.col-4 {
  width: 33.3333333333%;
}

.col-5 {
  width: 41.6666666667%;
}

.col-6 {
  width: 50%;
}

.col-7 {
  width: 58.3333333333%;
}

.col-8 {
  width: 66.6666666667%;
}

.col-9 {
  width: 75%;
}

.col-10 {
  width: 83.3333333333%;
}

.col-11 {
  width: 91.6666666667%;
}

.col-12 {
  width: 100%;
}

@media (max-width: 1024px) {
  .col-t-1 {
    width: 8.3333333333%;
  }

  .col-t-2 {
    width: 16.6666666667%;
  }

  .col-t-3 {
    width: 25%;
  }

  .col-t-4 {
    width: 33.3333333333%;
  }

  .col-t-5 {
    width: 41.6666666667%;
  }

  .col-t-6 {
    width: 50%;
  }

  .col-t-7 {
    width: 58.3333333333%;
  }

  .col-t-8 {
    width: 66.6666666667%;
  }

  .col-t-9 {
    width: 75%;
  }

  .col-t-10 {
    width: 83.3333333333%;
  }

  .col-t-11 {
    width: 91.6666666667%;
  }

  .col-t-12 {
    width: 100%;
  }
}
@media (max-width: 600px) {
  .col-p-1 {
    width: 8.3333333333%;
  }

  .col-p-2 {
    width: 16.6666666667%;
  }

  .col-p-3 {
    width: 25%;
  }

  .col-p-4 {
    width: 33.3333333333%;
  }

  .col-p-5 {
    width: 41.6666666667%;
  }

  .col-p-6 {
    width: 50%;
  }

  .col-p-7 {
    width: 58.3333333333%;
  }

  .col-p-8 {
    width: 66.6666666667%;
  }

  .col-p-9 {
    width: 75%;
  }

  .col-p-10 {
    width: 83.3333333333%;
  }

  .col-p-11 {
    width: 91.6666666667%;
  }

  .col-p-12 {
    width: 100%;
  }
}
.wrapper {
  width: 100%;
  min-height: 100%;
  overflow: hidden;
}

.preload {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 100;
}
.preload.finish {
  pointer-events: none;
  position: absolute;
}
.preload-fade {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background: #000;
}
.preload-img {
  position: relative;
  width: 130px;
  height: 130px;
}
.preload-img__triangle {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translateX(-50%) translateY(-50%);
  transform: translate(-50%, -50%) scale(0);
  transform-origin: 50% 65%;
}
.preload-img__logo {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translateX(-50%) translateY(-50%);
  margin-top: 19px;
}
.preload-img__logo img {
  width: 30px;
  opacity: 0;
  transform: scale(0.3) translateY(5px);
}

/* header begin */
.header {
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  padding-top: 45px;
  z-index: 9;
  color: #fff;
}
.header > .content {
  display: flex;
  align-items: center;
  justify-content: center;
}
.header__logo {
  margin-right: 26px;
}
.header-menu {
  display: flex;
  align-items: center;
  justify-content: center;
  list-style: none;
  flex: 1;
  gap: 60px;
}
.header-menu li {
  margin-right: 25px;
}
.header-menu li a {
  color: inherit;
  text-decoration: none;
}
.header-right {
  display: flex;
  align-items: center;
}
.header-btn {
  display: none;
}

@media (max-width: 1300px) {
  .header-menu {
    gap: 20px;
  }
}
@media (max-width: 770px) {
  .header-btn {
    display: block;
    position: absolute;
    right: 40px;
    top: 50%;
    margin-top: -15px;
  }

  .header-menu a:not(.header__logo) {
    display: none;
  }
}
@media (max-width: 600px) {
  .header-btn {
    margin-top: -20px;
  }

  .header__logo {
    width: 210px;
    display: block;
    margin: 0;
  }
}
.hero {
  position: relative;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  padding-bottom: 95px;
  padding-top: 100px;
  z-index: 1;
  background: #000;
}
.hero .content {
  position: relative;
  z-index: 2;
}
.hero-bg {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  right: -5%;
  background-size: cover;
  background-position: center;
  background-attachment: fixed;
  animation: hero-bg 20s ease-in-out infinite;
  z-index: 0;
  opacity: 0.7;
}
.hero-bg:before, .hero-bg:after {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 20%;
  background: linear-gradient(180deg, #000000 -55.86%, rgba(0, 0, 0, 0) 100%);
  z-index: 1;
}
.hero-bg:after {
  top: auto;
  bottom: 0;
  background: linear-gradient(0deg, #000000 -55.86%, rgba(0, 0, 0, 0) 100%);
}
.hero-bg-mask {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  right: -5%;
  left: -5%;
  mask: url("../img/start-mask.svg") no-repeat center;
  z-index: 1;
}
.hero-bg-mask__img {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background-size: cover;
  background-position: center;
  transform: scale(1.2);
  background-attachment: fixed;
  animation: hero-bg-star 20s ease-in-out infinite;
  animation-delay: 2s;
}
.hero-bg-mask:after {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background: #000;
}
.hero-content {
  position: relative;
  color: #fff;
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  width: 100%;
  flex: 1;
}
.hero-content__address {
  font-size: 0.85rem;
  margin-top: 1rem;
}
.hero-content .h1 {
  max-width: 100%;
  width: 400px;
  line-height: 1;
}
.hero-news {
  display: flex;
  gap: 37px;
  width: 490px;
  max-width: 100%;
}
.hero-news-itm {
  color: inherit;
  text-decoration: none;
  display: block;
}
.hero-news-itm__date {
  font-size: 0.75rem;
  opacity: 0.7;
  margin-bottom: 9px;
}
.hero-news-itm__title {
  line-height: 1.4;
}

.soc-block {
  text-align: right;
}

.soc-list {
  display: flex;
  justify-content: flex-end;
  gap: 1.37rem;
}
.soc-list-itm path {
  transition: all 0.3s ease-in-out;
}
.soc-list-itm:hover path {
  fill: #BED4CD;
}

.main-tel {
  display: inline-block;
  font-size: 3.125rem;
  color: #fff;
  text-decoration: none;
  font-family: "21 Cent";
  font-weight: 500;
  margin-top: 1.5rem;
}
.main-tel:hover {
  color: #BED4CD;
}

@keyframes hero-bg {
  0% {
    transform: translateX(0);
  }
  50% {
    transform: translateX(-5%);
  }
  100% {
    transform: translateX(0);
  }
}
@keyframes hero-bg-star {
  0% {
    transform: translateX(0) scale(1.2);
  }
  50% {
    transform: translateX(-8%) scale(1.3);
  }
  100% {
    transform: translateX(0) scale(1.2);
  }
}
@keyframes hero-bg-mask {
  0% {
    transform: translateX(0) scale(1);
  }
  50% {
    transform: translateX(8%) scale(1.1);
  }
  100% {
    transform: translateX(0) scale(1);
  }
}
@media (max-width: 770px) {
  .hero {
    padding-bottom: 45px;
  }
  .hero-news {
    display: none;
  }
  .hero-content {
    justify-content: center;
  }
  .hero-bg:after {
    height: 40%;
  }

  .main-tel {
    font-size: 2.75rem;
  }

  .hero-bg-mask {
    mask-size: 75%;
  }
}
@media (max-width: 600px) {
  .hero {
    padding-bottom: 25px;
  }
  .hero-content {
    display: block;
  }

  .main-tel {
    font-size: 2rem;
    margin-top: 0.5rem;
  }

  .soc-list-itm svg {
    width: 35px;
    height: 35px;
  }

  .soc-block {
    margin-top: 2rem;
  }
}